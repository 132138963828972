<template>

  <div>
    <message messageType="bubble">
      <div class="feedback__container">
        <div class="feedback__query">
          <p>{{ question }}</p>
        </div>
        <div class="feedback__yesno-button" v-if="showButtons">
          <button
            type="button"
            v-on:click="yesAction($event)"
            class="wc-button wc-button--secondary"
          ><span class="label">Yes</span></button>
          <button
            type="button"
            v-on:click="noAction($event)"
            class="wc-button wc-button--negative"
          ><span class="label">No</span></button>
        </div>
      </div>
    </message>
  </div>
</template>

<script>
import message from '@/components/common/Message/Message.vue';

export default {
  name: 'initial',
  components: {
    message,
  },
  props: {
    question: String,
    yesAction: Function,
    noAction: Function,
    showButtons: Boolean,
  },
  computed: {
  },
  methods: {}
};
</script>
