<template>
  <div>
    <message messageType="bubble">
      <p class="feedback__thankyou">
        {{text}}
      </p>
    </message>
  </div>
</template>

<script>
import message from '@/components/common/Message/Message.vue';

export default {
  name: 'thankyou',
  props: {
    text: String
  },
  components: {
    message,
  },
  computed: {
  },
  methods: {}
};
</script>
