<template>
  <div>
    <message messageType="error">
      {{text}}
    </message>
  </div>
</template>

<script>
import message from '@/components/common/Message/Message.vue';

export default {
  name: 'error',
  props: {
    text: String
  },
  components: {
    message,
  },
  computed: {
    errorMessage() {
      return this.text || 'An error has occurred.';
    },
  },
  methods: {}
};
</script>
