<template>
  <div>
    <initial
      v-if="step == 'initial'"
      :question="question"
      :yesAction="yesAction"
      :noAction="noAction"
      :showButtons="showButtons"
    ></initial>
    <comment
      v-else-if="step == 'comment'"
      :text="comment"
      :action="doSubmit"
      :showButtons="showButtons"
      ></comment>
    <thankyou v-else-if="step == 'thankyou'" :text="thankyou"></thankyou>
    <error v-else :text="errorMessage"></error>
  </div>
</template>

<script>
import axios from 'axios';
import { doAdobe } from '@/helpers/adobe';
import thankyou from './feedbackThumbs/thankyou.vue';
import error from './feedbackThumbs/error.vue';
import comment from './feedbackThumbs/comment.vue';
import initial from './feedbackThumbs/initial.vue';

export default {
  name: 'FeedbackThumbs',
  props: {
    args: String,
  },
  data: function data() {
    return {
      step: 'initial',
      id: null,
      key: null,
      errorMessage: 'An error has occurred.',
      showButtons: true,
    };
  },
  components: {
    thankyou,
    comment,
    initial,
    error,
  },
  computed: {
    arguments() {
      return JSON.parse(this.args);
    },
    feature() {
      return this.arguments.feature || '';
    },
    feature_ident() {
      return this.arguments.feature_ident || '';
    },
    question() {
      return this.arguments.question || 'Is this feature helpful?';
    },
    thankyou() {
      return this.arguments.thankyou || 'Thank you for your feedback.';
    },
    comment() {
      return this.arguments.comment || 'How can we improve?';
    },
  },
  methods: {
    setStep(step) {
      this.step = step;
    },
    submitInitialAction(score, feature, feature_ident, successStep) {
      this.showButtons = false;
      const args = [
        `score=${score}`,
        `feature=${encodeURIComponent(feature)}`,
        `feature_ident=${encodeURIComponent(feature_ident || '<empty>')}`,
      ];
      const uri = `/main-ajax-feedback?${args.join('&')}`;
      axios
        .post(uri, {},
          { headers: { 'X-Post-ID': nxDatalayer.global.post_id } })
        .then(({ data }) => {
          if (data.id) {
            this.id = data.id;
            this.key = data.key;
            this.step = successStep;
            this.showButtons = true;
          } else {
            this.errorMessage = data.error;
            this.step = 'error';
          }
        })
        .catch(() => {
          this.step = 'error';
        });
    },
    doFeedbackAdobe(score) {
      // event123 = feedback score. Numeric, use 0 for thumbs down, 1 for thumbs up.
      // event124 = feedback collected. Counter, number of times feedback has been collected
      // evar95 = feedback feature. Will be “search” for feedback on search
      // evar96 = feedback feature string. Will be the search term for feedback on search.
      const payload = {
        events: `event123=${score},event124`,
        eVar95: this.feature,
        eVar96: this.feature_ident,
      };
      doAdobe(payload, 'feedback');
    },
    yesAction() {
      this.doFeedbackAdobe(1);
      this.submitInitialAction(1, this.feature, this.feature_ident, 'thankyou');
    },
    noAction() {
      this.doFeedbackAdobe(0);
      this.submitInitialAction(0, this.feature, this.feature_ident, 'comment');
    },
    doSubmit(response) {
      const args = [
        `id=${this.id}`,
        `key=${this.key}`,
        `response=${response}`,
      ];
      const uri = `/main-ajax-feedback?${args.join('&')}`;
      this.showButtons = false;
      axios
        .post(uri, {},
          { headers: { 'X-Post-ID': nxDatalayer.global.post_id } })
        .then(({ data }) => {
          if (data.id) {
            this.step = 'thankyou';
          } else {
            this.errorMessage = data.error;
            this.step = 'error';
          }
        })
        .catch(() => {
          this.step = 'error';
        });
    },
  },
};
</script>
