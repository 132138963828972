<template>
  <div>
    <message messageType="bubble">
      <p class="feedback__submit-text">
        {{ text }}
      </p>
      <div>
        <updated-tip v-bind:message="errorMessage" tipType="error" v-if="errorMessage" />
        <textarea class="feedback__submit-textarea"
          maxlength="300"
          v-on:input="updateValue($event)"
          :value="comment"
        />
      </div>
      <div class="feedback__submit-button">
        <button
          type="button"
          class="wc-button wc-button--primary"
          style="margin-top: 10px"
          v-on:click="trySubmit($event)"
          v-if="showButtons"
        ><span class="label">Submit</span></button>
      </div>
    </message>
  </div>
</template>

<script>
import Message from '@/components/common/Message/Message.vue';
import UpdatedTip from '@/components/common/UpdatedTip/index';

export default {
  name: 'comment',
  components: {
    Message,
    UpdatedTip,
  },
  data: function data() {
    return {
      errorMessage: '',
      comment: '',
    };
  },
  props: {
    text: String,
    action: Function,
    showButtons: Boolean,
  },
  computed: {
  },
  methods: {
    trySubmit() {
      if (this.comment === '') {
        this.errorMessage = 'Please enter a reason.';
        return false;
      }
      this.action(this.comment);
      return false;
    },
    updateValue(evt) {
      this.comment = evt.target.value;
    },
  }
};
</script>
